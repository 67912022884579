<template>
  <apexchart
    v-if="series[0]?.data?.length"
    @legendClick="legendClick"
    :height="250"
    type="bar"
    :options="options"
    :series="series"
  />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  ritten: Array,
  rittenVergelijk: Array,
  rittenBam: [Array, Number],
  rittenTarget: [Array, Number],
  rittenLabel: {
    type: String,
    default: 'Aantal ritten',
  },
  waarden: Array,
  waardenVergelijk: Array,
  waardenBam: [Array, Number],
  waardenTarget: [Array, Number],
  waardenLabel: {
    type: String,
    default: 'Dag waarde',
  },
})

const gemiddeldeArrayNietLeeg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.filter(el => el > 0).length

const getRittenSeries = (items, fillColor = undefined, name = '') => {
  if (!items?.length) return null

  const gemiddelde = gemiddeldeArrayNietLeeg(props.ritten)

  return {
    name,
    fillColor,
    data: (items || []).map((el, index) => ({
      value: el,
      y: el,
      x: index + 1,
      fillColor,
      goals: [
        {
          name: 'BAM',
          value: parseFloat(Array.isArray(props.rittenBam) ? props.rittenBam[index] : props.rittenBam),
          y: parseFloat(Array.isArray(props.rittenBam) ? props.rittenBam[index] : props.rittenBam),
          strokeHeight: 2,
          strokeColor: '#eb3838',
        },
        {
          name: 'TARGET',
          value: parseFloat(Array.isArray(props.rittenTarget) ? props.rittenTarget[index] : props.rittenTarget),
          y: parseFloat(Array.isArray(props.rittenTarget) ? props.rittenTarget[index] : props.rittenTarget),
          strokeHeight: 2,
          strokeColor: '#b1c94e',
        },
        {
          name: 'GEMIDDELDE',
          value: gemiddelde,
          y: gemiddelde,
          strokeHeight: 2,
          strokeColor: '#c669ca',
        },
      ].filter(el => !!el.y),
    })),
  }
}

const getWaardenSeries = (items, fillColor = undefined, name = '') => {
  if (!items?.length) return null

  const gemiddelde = gemiddeldeArrayNietLeeg(props.waarden)

  return {
    name,
    fillColor,
    data: (items || []).map((el, index) => ({
      value: el,
      y: el,
      x: index + 1,
      fillColor,
      goals: [
        {
          name: `BAM ${name}`,
          value: parseFloat(Array.isArray(props.waardenBam) ? props.waardenBam[index] : props.waardenBam),
          y: parseFloat(Array.isArray(props.waardenBam) ? props.waardenBam[index] : props.waardenBam),
          strokeHeight: 2,
          strokeColor: '#eb3838',
        },
        {
          name: `TARGET ${name}`,
          value: parseFloat(Array.isArray(props.waardenTarget) ? props.waardenTarget[index] : props.waardenTarget),
          y: parseFloat(Array.isArray(props.waardenTarget) ? props.waardenTarget[index] : props.waardenTarget),
          strokeHeight: 2,
          strokeColor: '#b1c94e',
        },
        {
          name: 'GEMIDDELDE',
          value: gemiddelde,
          y: gemiddelde,
          width: 200,
          strokeHeight: 2,
          strokeColor: '#c669ca',
        },
      ].filter(el => !!el.y),
    })),
  }
}

const series = computed(() => [
  getRittenSeries(props.ritten, '#008ffb', props.rittenLabel),
  getRittenSeries(props.rittenVergelijk, '#B0C4DE', `${props.rittenLabel} (Vergeleken)`),
  getWaardenSeries(props.waarden, '#008ffb', props.waardenLabel),
  getWaardenSeries(props.waardenVergelijk, '#B0C4DE', `${props.waardenLabel} (Vergeleken)`),
].filter(el => !!el))

const formatters = computed(() => [
  props.ritten?.length ? (val) => `${parseInt(Math.round(Number(val)), 10)} Ritten` : null,
  props.rittenVergelijk?.length ? (val) => `${parseInt(Math.round(Number(val)), 10)} Ritten` : null,
  props.waarden?.length ? (val) => `€ ${Number(val).toFixed(2)}` : null,
  props.waardenVergelijk?.length ? (val) => `€ ${Number(val).toFixed(2)}` : null,
].filter(el => !!el))

const categories = computed(() => (props.ritten || []).map((el, index) => String(index + 1)))

let lastClicked = -1
const legendClick = (chartContext, seriesIndex) => {
  const same = lastClicked === seriesIndex
  series.value.forEach((s, idx) => {
    if (same || idx === seriesIndex) {
      chartContext.showSeries(s.name)
      return
    }
    chartContext.hideSeries(s.name)
  })
  lastClicked = same ? -1 : seriesIndex
}

const options = computed(() => ({
  chart: {
    type: 'bar',
    height: 350,
    zoom: false,
  },
  legend: {
    markers: {
      fillColors: series.value.map(el => el.fillColor),
    },
    onItemClick: {
      toggleDataSeries: false,
    },
  },
  toolbar: {
    tools: {
      download: '<i class="fas fa-save" width="50" height="20" />',
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter(val, ctx) {
      const { seriesIndex } = ctx
      const formatter = formatters.value[seriesIndex]
      return typeof formatter === 'function' ? formatter(val) : parseInt(val, 10)
    },
    offsetY: -20,
    style: {
      fontSize: '8px',
      colors: ['#304758'],
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    type: 'category',
    tickPlacement: 'on',
    categories: categories.value,
    overwriteCategories: categories.value,
    tickAmount: categories.value.length,
    min: categories.value[0],
    max: categories.value[categories.value.length - 1],
  },
  yaxis: {
    enabled: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
  },
  /*
  yaxis: [
    {
      enabled: true,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    {
      opposite: true,
      enabled: true,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  ],
  */
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter(val, ctx) {
        const { seriesIndex } = ctx
        const formatter = formatters.value[seriesIndex]
        return typeof formatter === 'function' ? formatter(val) : parseInt(val, 10)
      },
    },
  },
}))
</script>
